.main-about-info {
	--mainAboutInfoPaddingBottom: clamp(10px, (44/1510) * 100vw, 44px);
	width: 100%;

	&__body {
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}

	&__block-title {
		flex: 1 1 80%;
		padding-bottom: var(--mainAboutInfoPaddingBottom);
		padding-right: 25px;
		position: relative;
	}

	&__location {
		font-family: $fontPlayfair;
		font-size: clamp(12px, (16/1510) * 100vw, 16px);
		color: $white;
		cursor: default;
		flex: 1 1 20%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding-bottom: var(--mainAboutInfoPaddingBottom);
	}

	&__title,
	&__subtitle {
		font-family: $fontPlayfair;
		color: $white;
		cursor: default;
	}

	&__title {
		font-size: clamp(28px, (96/1510) * 100vw, 96px);
	}

	&__subtitle {
		display: inline-block;
		font-size: clamp(24px, (64/1510) * 100vw, 64px);
		line-height: 1;
		margin-right: auto;
	}

	&__description {
		max-width: 835px;
		font-weight: 300;
		font-size: clamp(16px, (24/1510) * 100vw, 24px);
		color: $white;
		cursor: default;

		&--mt {
			margin-top: 15px;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 1575px) {
		&__title {
			transform: translateX(0);
		}
	}

	@media (max-width: 1199px) {
		padding-top: 20px;
	}

	@media (max-width: ($laptop + px)) {
		--mainAboutInfoPaddingBottom: 0;

		&__block-title {
			flex: 1 1 85%;
			padding-bottom: 15px;
		}

		&__location {
			flex: 1 1 15%;
		}
	}

	@media (max-width: 1199px) {
		&__body {
			margin-top: -50px;
		}
	}

	@media (max-width: 645px) {
		&__body {
			margin-top: 0;
		}

		&__title {
			margin-bottom: 16px;
			line-height: 1;
		}

		&__subtitle {
			line-height: 1;
		}

		&__block-title {
			flex: 1 1 100%;
			border-right: 1px solid transparent;
		}

		&__location {
			display: none;
		}

		&__description {
			letter-spacing: -0.5px;
		}
	}
}
