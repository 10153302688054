.main-hero {
	--mainHeroWidth: 330px;
	--mainHeroEffectHeight: clamp(62px, (113/1510) * 100vw, 113px);

	width: 100%;
	height: 100%;
	margin-top: calc(-1 * var(--contentPaddingTop));

	&__body {
		position: relative;
		width: 100%;
	}

	&__background {
		position: relative;
		width: 100%;
		height: 100vh;
		max-height: clamp(320px, (887/1510) * 100vw, 887px);
		background: linear-gradient(
				270.27deg,
				rgba(0, 0, 0, 0.075) 26.81%,
				rgba(255, 223, 223, 0) 56.63%
			),
			rgba(0, 0, 0, 0.32);
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: clamp(62px, (113/1510) * 100vw, 113px);
			z-index: 10;
			overflow: hidden;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: -1;
			animation: zoomEffect 90s ease-in-out infinite;
		}
	}

	&__about-info {
		position: absolute;
		max-width: var(--container);
		padding: 0px var(--paddingContainer);
		top: 50%;
		left: 50%;
		transform: translateX(-50%)
			translateY(calc(-50% - var(--mainHeroEffectHeight) / 2));
	}

	@media (max-width: 1510px) {
		--mainHeroWidth: 1;
		&__about-info {
			left: 50%;
			padding: 0px 40px;
		}
	}

	@media (max-width: 565px) {
		&__about-info {
			padding: 0px 20px;
		}
		.container {
			max-width: auto;
			padding: 0;
		}
	}
}

@keyframes zoomEffect {
	0%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}
}
