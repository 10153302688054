.footer-contact {

	&__address {
		font-weight: 500;
		color: $white;
		margin-bottom: 24px;
	}

	&__item {
		margin-bottom: 16px;

		&:hover .footer-contact__link {
			opacity: 0.65;
		}
	}

	&__icon {
		margin-right: 12px;
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: $white;
		transition: opacity 0.15s ease;
		font-size: clamp(14px, (16/1024)*100vw, 16px);
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
	}

	@media (max-width: 1315px) {
		&__social {
			display: none;
		}

		&__icon {
			display: none;
		}
	}
}
