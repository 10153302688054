.management {
	--mainManagementPaddingTop: clamp(60px, (140/1510) * 100vw, 140px);
	--managementHeight: clamp(288px, (390/1510) * 100vw, 390px);

	--navButtonSize: 48px;

	position: relative;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 1;

	.swiper {
		padding: 15px 20px calc(36px + var(--bulletSizeActive)) 20px;
	}

	.swiper-slide {
		height: auto;
		min-height: var(--managementHeight);
	}

	&__body {
		position: relative;
	}

	&__title {
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__navigation {
		position: absolute;
		top: calc(50%);
		left: 50%;
		transform: translate(-50%, 50%);
		width: calc(100% + var(--navButtonSize) * 2);
		display: flex;
		justify-content: space-between;
		z-index: 1000;
	}

	&__next,
	&__prev {
		width: var(--navButtonSize);
		height: var(--navButtonSize);
		border-radius: 50%;
		box-shadow: $defaultShadow;
		background: url(../img/icons/arrowControlSlideHover.svg) $white
			no-repeat center / 24px;
		transition: all 0.2s ease;
	}

	&__next {
		transform: rotate(180deg);
	}

	&__button-disabled {
		visibility: hidden;
		opacity: 0;
		cursor: default;
	}

	@media (min-width: 990px) {
		.bullets {
			display: none;
		}
	}

	@media (max-width: 990px) {
		&__navigation {
			display: none;
		}
	}
}
