.services-card {
	--servicesCardIconSize: clamp(40px, (46/1155) * 100vw, 46px);
	--servicesCardHeight: 352px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	padding: clamp(24px, (32/1510) * 100vw, 32px);
	background-color: $white;
	font-size: clamp(14px, (18/768) * 100vw, 18px);
	max-width: auto;
	@include animationLiftingInitial;

	&__body {
		text-align: left;
	}

	&__icon {
		width: var(--servicesCardIconSize);
		height: var(--servicesCardIconSize);
		fill: $darkBlue;
		transition: all 0.4s ease;
		margin-bottom: 24px;
	}

	&__text {
		font-size: clamp(14px, (18/768), 18px);
		color: $darkBlue;
		cursor: default;
		transition: all 0.4s ease;
	}

	&:hover {
		@include animationLiftingHover(1.025, $defaultShadowHover);

		.services-card__icon {
			fill: $blue;
		}

		.services-card__text {
			color: $blue;
		}
	}
}
