.projects-filter-country {
	width: 100%;
	height: 78px;
	display: flex;
	align-items: center;
	gap: 16px;
	overflow: auto;

	&__item {
		cursor: pointer;
	}

	&__link {
		font-weight: 400;
		font-size: 20px;
		color: $black;
		padding: 15px 20px;
		user-select: none;
		border: 1px solid $white;
		background-color: $white;
		transition: all 0.3s ease;

		&:hover,
		&.active {
			border: 1px solid $darkBlue;
			background-color: $darkBlue;
			color: $white;
		}
	}

	@media (max-width: 1024px) {
		gap: 8px;

		&__link {
			font-size: 18px;
		}
	}

	@media (max-width: 840px) {
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0;
			height: 3px;
		}

		&::-webkit-scrollbar-track {
			background-color: #e4e4e4;
			border-radius: 100px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $blue;
			box-shadow: inset 2px 2px 3px 0 rgba($blue, 1);
		}
	}
}
