@mixin pointArrow {
	&::before {
		content: '';
		display: inline-block;
		border: var(--pointArrowSize) solid transparent;
		border-top: var(--pointArrowSize) solid $white;
		position: absolute;
		bottom: calc(-2 * var(--pointArrowSize));
		position: absolute;
		left: calc(var(--pointWidthMin) / 2);
		transform: translateX(-50%);
		z-index: var(--zIndexArrow);
	}
}

.map-point {
	--pointArrowSize: clamp(5px, (10/1366) * 100vw, 10px);
	--pointBorderRadius: clamp(2.5px, (5/1366) * 100vw, 5px);

	--zIndexBody: 3;
	--zIndexArrow: 2;
	--zIndexLocation: 1;

	--pointWidthMin: clamp(26px, (53/1366) * 100vw, 53px);
	--pointWidth: clamp(151px, (191/1366) * 100vw, 191px);
	--pointHeight: clamp(67px, (80/1366) * 100vw, 80px);

	position: absolute;
	min-width: var(--pointWidthMin);
	min-height: var(--pointWidthMin);
	background: transparent;

	&__preview {
		position: absolute;
		bottom: 0;
		left: 0;
		width: var(--pointWidthMin);
		height: var(--pointWidthMin);
		background-color: $white;
		box-shadow: $defaultShadow;
		border-radius: var(--pointBorderRadius);
		z-index: var(--zIndexBody);
		transition: all 0.3s ease;
		@include pointArrow;
	}

	img {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		padding: clamp(2px, (5/1366) * 100vw, 5px);
		border-radius: calc(var(--pointBorderRadius) * 2);
	}

	&__button {
		position: absolute;
		top: -25px;
		left: 0;
		width: var(--pointWidth);
		height: var(--pointHeight);
		overflow: visible;
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
		font-weight: 500;
		font-size: 14px;
		color: $black;
		text-align: left;
		padding: 10px 35px 10px 12px;
		border-radius: var(--pointBorderRadius);
		background: url(../img/icons/arrowBlack.svg) $white no-repeat bottom
			15px right 15px / 22px 10px;
		box-shadow: $defaultShadow;
		transform: translateY(calc(-1 * var(--pointWidthMin) / 2));
		transition: all 0.3s ease;
		z-index: var(--zIndexBody);
		@include pointArrow;

		span {
			@include textTruncateVertical(2);
		}
	}

	&::after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 28px;
		position: absolute;
		bottom: -30px;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
		background: url(../img/icons/location.svg) transparent no-repeat 0 /
			contain;
		z-index: var(--zIndexLocation);
	}

	&--0 {
		top: 36%;
		right: 42%;
	}

	&--1 {
		top: 42%;
		right: 37%;
	}

	&--2 {
		top: 45%;
		right: 32%;
	}

	&--3 {
		top: 54%;
		right: 40%;
	}

	&--4 {
		top: 51%;
		right: 20%;
	}

	@media (max-width: 1366px) {
		&__button {
			span {
				@include textTruncateVertical(2);
			}
		}
	}

	@media (max-width: ($tablet + px)) {
		&__button {
			background-image: none;
		}
	}

	@media (max-width: 599px) {
		&__button {
			display: none;
		}
	}
}
