.services-engineering {
	--gap: 20px;
	width: 100%;

	&__title {
		margin-bottom: 32px;
	}

	&__cards {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: var(--gap);
	}

	&__card {
		position: relative;
		min-height: 276px;
		flex: 0 0 calc(33% - var(--gap));
		z-index: 9;

		&:hover {
			z-index: 10;

			.services-engineering__card-hover {
				display: block;
				opacity: 1;
			}
		}

		h4 {
			font-size: 20px;
			// @include textTruncateVertical(3);
		}

		p {
			margin-top: 8px;
			font-size: 18px;
			// @include textTruncateVertical(2);
		}
	}

	&__card-hover {
		display: block;
		padding: 32px 24px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-height: 276px;
		opacity: 0;
		background-color: $white;
		box-shadow: 0px 4px 16px rgba(117, 117, 117, 0.12);
		border: 1px solid rgba(2, 5, 35, 0.04);

		font-weight: 500;
		font-size: 16px;
		line-height: 160%;
		color: $blue;

		transition: all 0.4s ease;

		ul li {
			list-style: inherit;
			margin-left: 24px;
		}
	}

	@media (max-width: 1366px) {
		&__card {
			flex: 0 0 calc(50% - var(--gap));
		}
	}

	@media (max-width: 715px) {
		&__card {
			flex: 0 0 100%;
		}
	}
}
