.not-found {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: var(--headerHeight);

	&__body {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: default;
	}

	&__image {
		position: relative;
		width: 100%;
		margin-bottom: clamp(32px, (24/1920)*100vw, 24px);
		padding-bottom: 21%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: scale-down;
		}
	}

	&__title {
		font-size: clamp(18px, (48/1024)*100vw, 48px);
		text-align: center;
		margin-bottom: 24px;
		padding: 0px 25px;
		color: #000;
	}

	&__button {
		font-size: clamp(16px, (24/1024)*100vw, 24px);
		@include defaultTextUnderline;
		color: #061D38;

		&::after {
			content: none;
		}

		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: 1024px) {
		&__image {
			margin-bottom: 48px;
			padding-bottom: 25%;
		}
	}

	@media (max-width: 525px) {
		&__image {
			margin-bottom: 36px;
			padding-bottom: 30%;
		}
	}
}
