.contact-dropdown {
	--contactDropdownWidth: 595px;
	--contactDropdownMapHeight: clamp(200px, (433/991) * 100vh, 433px);
	--contactDropdownInfoHeight: clamp(290px, (335/681) * 100vh, 335px);
	--contactDropdownHeight: calc(
		var(--contactDropdownMapHeight) + var(--contactDropdownInfoHeight)
	);
	width: var(--contactDropdownWidth);
	height: var(--contactDropdownHeight);

	&__close {
		--contactCloseSize: 38px;
		position: absolute;
		top: calc(var(--contactCloseSize) / 2);
		right: calc(var(--contactCloseSize) / 2);
		width: var(--contactCloseSize);
		height: var(--contactCloseSize);
		background-color: $greyDark;
		z-index: $zIndexContactDropdownClose;
		border-radius: 50%;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 50%;
			height: 1px;
			background-color: $white;
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__body-top {
		flex: 0 1 var(--contactDropdownMapHeight);
		height: 100%;
	}

	&__navigation,
	&__language {
		display: none;
	}

	&__map {
		width: 100%;
	}

	&__info {
		flex: 0 1 var(--contactDropdownInfoHeight);
		overflow: auto;
	}

	@media (max-width: 991px) {
		top: var(--headerHeight);
		--contactDropdownWidth: 100%;
		--contactDropdownHeight: 100%;

		&__close {
			display: none;
		}

		&__body-top {
			display: flex;
			flex: 1 1 55%;
		}

		&__navigation {
			flex: 1 0 250px;
			display: flex;
			position: relative;
			background-color: $white;
		}

		&__map {
			flex: 0 1 auto;
		}

		&__info {
			flex: 1 1 45%;
			padding-bottom: 40px;
		}
	}

	@media (max-width: 645px) {
		&__body {
			height: auto;
		}

		&__body-top {
			position: relative;
			flex: 1 1 50%;
		}

		&__language {
			display: block;
			position: absolute;
			top: 15px;
			left: var(--paddingContainer);
			z-index: $zIndexContactDropdown;
		}

		&__navigation {
			flex: 1 1 auto;
		}

		&__map {
			display: none;
		}

		&__info {
			flex: 1 1 65%;
			padding-bottom: 40px;
		}
	}
}
