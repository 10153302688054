.button-link {
	--contentButtonLine: 37%;
	position: relative;
	font-family: $fontPlayfair;
	font-size: 21px;
	letter-spacing: -0.5px;
	padding-bottom: 8px;

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: var(--contentButtonLine);
		background-color: currentColor;
		transition: width 0.3s ease;
	}

	&:hover {
		--contentButtonLine: 100%;
	}

	&--blue {
		color: $blue;
	}

	&--blue-dark {
		color: $blueDark;
	}

	&--white {
		color: $white;
	}

	&--black {
		color: $black;
	}
}
