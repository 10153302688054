.map-svg {
	svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: $grey;
		stroke-width: 0.4px;
		stroke: $white;
		cursor: default;
	}

	&__path {
		position: absolute;
		top: 45%;
		right: 19%;

		path {
			fill: transparent;
			stroke: #8cd1bc;
			stroke-width: 0.98019;
			stroke-dasharray: 15.68 15.68;
		}

		svg {
			width: clamp(109px, (316/1920) * 100vw, 316px);
			height: clamp(70px, (202/1920) * 100vw, 202px);
		}
	}
}
