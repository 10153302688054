.footer-about {
	&__list:not(:last-child) {
		margin-bottom: clamp(24px, (50/1024) * 100vw, 50px);
	}

	&__item {
		margin-bottom: 16px;

		&:hover .footer-about__link {
			opacity: 0.65;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: $white;
		transition: opacity 0.15s ease;
		font-size: clamp(14px, (16/1024) * 100vw, 16px);
		cursor: pointer;
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
		display: none;
	}

	@media (max-width: 1315px) {
		&__social {
			display: flex;
		}
	}

	@media (max-width: 680px) {
		&__social {
			display: none;
		}
	}
}
