.bullets {
	width: 100%;
	height: var(--bulletSizeActive);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0 !important;

	&__bullet {
		display: inline-block;
		width: var(--bulletSize);
		height: var(--bulletSize);
		border-radius: 50%;
		background-color: #e6e9ed;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 10px;
		}

		&--active {
			width: var(--bulletSizeActive);
			height: var(--bulletSizeActive);
			background-color: #061d38;
		}
	}

	// @media (max-width: 990px) {
	// 	display: none !important;
	// }
}
