.management-info {
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;
	display: flex;
	flex-direction: column;
	user-select: none;

	&__image {
		position: relative;
		width: 100%;
		background-color: $blueDark;
		overflow: hidden;
		padding-bottom: 84%;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 0 43%;
			z-index: 1;
		}
	}

	&__text {
		flex: 1 0 auto;
		border: 1px solid #D0DBE3;
		border-top: 1px solid transparent;
		padding-bottom: 12px;
	}

	&__full-name {
		width: 100%;
		font-size: 16px;
		font-weight: 600;
		color: #061D38;
		margin-bottom: 12px;
		cursor: default;
		padding: 20px 10px 0 10px;
	}

	&__position {
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		color: $black;
		cursor: default;
		padding: 0px 15px;
	}
}
