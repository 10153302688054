.projects-dropdown-list {
	max-width: var(--projectsDropdownListWidth);
	padding: clamp(24px, (32/665) * 100vw, 32px);
	border: 1px solid transparent;
	box-shadow: $defaultShadow;
	background-color: $white;

	&__item:not(:last-child) {
		margin-bottom: 32px;
	}

	&__link {
		font-size: 14px;
		font-weight: 600;
		color: $black;
		transition: color 0.2s ease;

		&:hover {
			color: $blue;
		}
	}

	@media (max-width: 499px) {
		min-width: 100%;
	}
}
