.main-about-tabs {
	--tabsPaddingTop: clamp(20px, (52/1920) * 100vw, 52px);
	--tabsContentHeight: clamp(300px, (470/1510) * 100vw, 470px);
	--tabsContentTextHeight: clamp(260px, (315/1510) * 100vw, 315px);
	--tabsContentPaddingLeft: clamp(84px, (135/1510) * 100vw, 135px);
	--navButtonWidth: clamp(148px, (388/1510) * 100vw, 388px);
	--navButtonHeight: clamp(55px, (118/1510) * 100vw, 118px);
	position: relative;
	width: 100%;
	height: 100%;

	&__body {
		width: 100%;
		height: 100%;
	}

	&__title {
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__nav {
		display: flex;
		justify-content: center;
		overflow-x: auto;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__nav-button {
		--navButtonPaddingX: 24px;
		flex: 0 0 var(--navButtonWidth);
		position: relative;
		min-height: var(--navButtonHeight);
		font-weight: 500;
		text-align: left;
		user-select: none;
		font-size: clamp(11px, (16/1024) * 100vw, 16px);
		padding: 0 var(--navButtonPaddingX) 0 var(--navButtonPaddingX);
		letter-spacing: 0.5px;
		color: $grey3;
		border: 1px solid $grey;
		z-index: 1;

		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: clamp(4px, (8/1024) * 100vw, 8px);

		&.active {
			background-color: $darkBlue;
			color: $white;
			font-weight: 600;
			border-bottom-left-radius: clamp(20px, (40/1024) * 100vw, 40px);
			border-top-right-radius: clamp(20px, (40/1024) * 100vw, 40px);
			border: 1px solid $blueDark;
			transition: color 0.5s ease, border-radius 0.5s ease,
				background-color 0.5s ease;
		}

		&:hover:not(.active) {
			border: 1px solid $blueDark;
			transition: border 0.25s ease;
		}

		span:first-child {
			color: #8e8e8e;
			font-size: clamp(10px, (16/1024) * 100vw, 16px);
		}

		span:last-child {
			@include textTruncateVertical(2);
			padding-bottom: 4px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		height: 100%;
	}

	&__item-list {
		flex: 0 1 45%;
		display: flex;
		column-gap: 24px;
		flex-direction: column;
	}

	&__item-num {
		font-family: $fontPlayfair;
		font-weight: 700;
		font-size: clamp(48px, (64/991) * 100vw, 64px);
	}

	&__item-text {
		margin-top: 10px;
		font-size: clamp(14px, (16/991) * 100vw, 16px);
	}

	&__content {
		position: relative;
		min-height: var(--tabsContentHeight);
	}

	&__content-item {
		display: flex;
		column-gap: clamp(25px, (130/1920) * 100vw, 130px);
		position: absolute;
		top: var(--tabsPaddingTop);
		width: 100%;
		overflow: hidden;
		visibility: hidden;
		cursor: default;
		opacity: 0;

		&.active {
			position: relative;
			visibility: visible;
			opacity: 1;

			.main-about-tabs__content-text {
				visibility: visible;
				opacity: 1;
			}

			.main-about-tabs__content-image {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__content-left,
	&__content-right {
		display: flex;
		flex-direction: column;
	}

	&__content-left {
		flex: 1 1 65%;
	}

	&__content-right {
		flex: 1 1 35%;
	}

	&__content-text {
		position: relative;
		width: 100%;
		min-height: var(--tabsContentTextHeight);
		font-size: clamp(14px, (16/1510) * 100vw, 16px);
		font-weight: 400;
		letter-spacing: -0.5px;
		overflow: hidden;
		margin-bottom: 30px;
		box-shadow: 0px -29px 8px -3px rgba(255, 255, 255, 0.9) inset;
		visibility: hidden;
		opacity: 0;
		transition: opacity 2s ease;

		p:not(:last-child) {
			margin-bottom: 30px;
		}

		h6 {
			font-weight: 600;
			color: $grey3;
			font-size: 16px;
			margin-bottom: 24px;
		}
	}

	&__content-button {
		margin-top: auto;
		font-size: clamp(16px, (16/1510) * 100vw, 16px);
		align-self: flex-start;
		color: #061d38;

		&.hide {
			display: none;
		}
	}

	&__content-image {
		position: relative;
		overflow: hidden;
		padding-bottom: 67.5%;
		@include defaultBorderRadius;
		visibility: hidden;
		opacity: 0;
		transition: opacity 2s ease;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: -1;
		}
	}

	&__item {
		&:not(:first-child) {
			margin-bottom: 25px;
		}

		ul li {
			list-style: inside !important;
			margin-left: 15px;
		}

		ol {
			list-style: none;
			counter-reset: my-awesome-counter;

			li {
				counter-increment: my-awesome-counter;
				&::before {
					content: counter(my-awesome-counter) '. ';
				}
			}
		}
	}

	@media (max-width: 1024px) {
		--tabsContentTextHeight: 100%;
		&__content-item {
			flex-direction: column;
			gap: 20px 0;
		}

		&__nav {
			display: flex;
			justify-content: flex-start;
			overflow-x: auto;
			width: 100%;
		}

		&__nav-button {
			flex: 0 1 33.333%;
			justify-content: flex-start;
			padding: 8px 4px 4px 12px;
		}

		&__list {
			flex-wrap: nowrap;
		}

		&__item-list {
			flex: 0 1 20%;
		}
	}

	@media (max-width: 695px) {
		&__nav-button {
			flex: 1 0 185px;
			height: 60px;

			&.active {
				border-bottom-left-radius: 20px;
				border-top-right-radius: 20px;
			}

			span:last-child {
				@include textTruncateVertical(3);
				padding-bottom: 4px;
			}
		}

		&__content-image {
			display: none;
		}

		&__list {
			flex-wrap: wrap;
		}

		&__item-list {
			flex: 0 1 100%;
			align-items: center;
		}
	}
}
