.about-file {
	--aboutFileItemHeight: 95px;
	--aboutFilePaddingTop: clamp(60px, (140/1510) * 100vw, 140px);
	--spaceItemX: 24px;
	width: 100%;

	&__body {
		position: relative;
	}

	&__title {
		margin-bottom: 64px;
	}

	&__list {
		transition: all 0.2s ease;
		overflow: hidden;
	}

	&__handler {
		display: flex;
		flex-wrap: wrap;
		gap: 48px 20px;
		margin-bottom: 32px;
		transition: all 0.2s ease;
	}

	&__item {
		// flex: 0 0 334px;
		flex: 0 1 calc(50% - var(--spaceItemX));
		padding-right: 32px;

		min-height: var(--aboutFileItemHeight);
	}

	&__structure {
		font-family: $fontPlayfair;
		@include defaultTextUnderline;
		font-size: clamp(18px, (24/768) * 100vw, 24px);
		letter-spacing: -0.5px;
		color: $blue;
		transition: color 0.3s ease;

		&:hover {
			color: $black;
			@include defaultTextUnderline;
		}
	}

	&__controlls {
		margin-top: 32px;
		margin-bottom: 70px;
	}

	&__controll {
		font-family: $fontPlayfairDefault;
		text-decoration: none;
		font-weight: 600;
		font-size: 18px;

		&::after {
			display: none;
		}

		&:first-child {
			margin-right: 48px;
		}
	}

	@media (max-width: 1000px) {
		&__item {
			flex: 0 1 100%;
		}
	}
}
