.services {
	width: 100%;

	&__body {
		position: relative;
	}

	&__info {
		margin-bottom: 64px;
	}

	&__text {
		margin-bottom: 16px;
	}

	&__emphasis {
		font-weight: 600;
	}

	&__projects {
		margin-bottom: 64px;
	}
}
