.about {
	width: 100%;
	height: 100%;

	&__info,
	&__management,
	&__regular-info {
		margin-bottom: clamp(48px, (140/1510)*100vw, 140px);
	}
}
