.about-regular-info {
	--aboutRegularInfoPaddingTop: clamp(60px, (140/1510) * 100vw, 140px);
	--spaceItemX: 24px;

	&__body {
		display: flex;
		flex-direction: column;
	}

	&__list {
		transition: all 0.2s ease;
		overflow: hidden;
	}

	&__handler {
		display: flex;
		flex-wrap: wrap;
		column-gap: var(--spaceItemX);
		row-gap: 32px;
		margin-bottom: 32px;
		transition: all 0.2s ease;
	}

	&__item {
		flex: 0 1 calc(50% - var(--spaceItemX));
		padding-right: 32px;
	}

	&__date {
		display: inline-block;
		color: $grey3;
		margin-bottom: 8px;
	}

	&__doc-title {
		font-size: 18px;
		color: $black;
		font-weight: 500;
		margin-bottom: 16px;
	}

	&__button {
		font-size: 16px;
	}

	&__button-all {
		font-weight: 600;
		letter-spacing: -0.5px;
		font-size: clamp(16px, (18/1024) * 100vw, 18px);
		color: $blue;
		align-self: flex-start;
	}

	@media (max-width: 1200px) {
		&__item {
			flex: 0 1 100%;
		}
		&__doc-title {
			font-size: 16px;
		}
	}

	@media (max-width: ($tablet + px)) {
		&__button-all {
			align-self: center;
		}
	}
}
