.main-map {
	--mainMapHeight: 100%;
	width: 100%;
	height: var(--mainMapHeight);

	&__body {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
	}

	&__info {
		padding: clamp(32px, (64/1366) * 100vw, 64px)
			clamp(16px, (32/1366) * 100vw, 32px);
		flex: 0 0 636px;
		box-shadow: $defaultShadow;
		margin-top: 110px;
	}

	&__title {
		margin-bottom: clamp(24px, (32/1920) * 100vw, 32px);
		cursor: default;
		color: $darkBlue;
		font-size: clamp(22px, (48/1920) * 100vw, 48px);
	}

	&__description {
		font-size: clamp(14px, (16/1024) * 100vw, 16px);
		font-weight: 400;
		margin-bottom: 24px;
		cursor: default;
	}

	&__country {
		font-family: $fontPlayfair;
		font-size: clamp(16px, (18/1024) * 100vw, 18px);
		text-transform: uppercase;
		color: $blueDark;
		border-left: 1px solid $blueDark;
		padding-left: 16px;
		margin-left: 32px;
		cursor: default;
	}

	&__world {
		position: absolute;
		flex: 0 1 100%;
		left: 34%;
		top: -17%;
		width: clamp(400px, (1138/1920) * 100vw, 1138px);
		height: clamp(400px, (713/1920) * 100vw, 713px);
	}

	&__svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 1366px) {
		&__world {
			left: 0;
			position: relative;
			transform: translateY(0);
			margin: 0 auto;
			height: clamp(300px, (713/1366) * 100vw, 713px);
		}

		&__info {
			flex: 0 0 636px;
			margin-top: 0;
			margin-bottom: clamp(1px, (15/1366) * 100vw, 15px);
		}

		&__body {
			display: block;
		}
	}

	@media (max-width: 500px) {
		&__world {
			width: 90%;
			height: clamp(200px, (300/500) * 100vw, 300px);
		}

		.container {
			max-width: auto;
			padding: 0;
		}

		&__country {
			margin-left: 3px;
		}
	}
}
