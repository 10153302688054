.main {

	&__hero,
	&__tabs,
	&__news {
		margin-bottom: clamp(48px, (140/1510)*100vw, 140px);
	}

	&__map {
		margin-bottom: clamp(48px, (250/1510)*100vw, 250px);
	}

	&__projects {
		margin:
			0
			auto
			clamp(48px, (240/1920)*100vw, 240px)
			auto;
	}

	@media (max-width: 1366px) {
		&__projects {
			margin-bottom: clamp(48px, (115/1366)*100vw, 115px);
		}
	}
}
